import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Avatar,
  Paper,
  Stack,
  Container,
  InputAdornment,
  Card,
  IconButton,
  useTheme,
  ThemeProvider,
  createTheme,
  CssBaseline,
  useMediaQuery,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  DarkMode,
  LightMode,
  Menu,
  Search,
  Send,
  SettingsSharp,
} from "@mui/icons-material";
import { WebService } from "../../Services/WebService";
import { StandardConst } from "../../Services/StandardConst";
import { useSelector } from "react-redux";
import ActionButton from "../../Services/ActionButton";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import AppbarComponent from "../../Services/AppbarComponent";

const StyledChatContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "90vh",
  gap: 2,
  padding: 0,
  backgroundColor: theme.palette.mode === "dark" ? "#1a1a1a" : "#f5f5f5",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
    height: "calc(100vh - 70px)",
  },
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  width: "300px",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#ffffff",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ChatArea = styled(Paper)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#ffffff",
}));

const MessageContainer = styled(Box)(({ sent }) => ({
  display: "flex",
  justifyContent: sent ? "flex-end" : "flex-start",
  marginBottom: "8px",
}));

const MessageBubble = styled(Card)(({ theme, sent }) => ({
  maxWidth: "70%",
  padding: "12px 16px",
  backgroundColor: sent
    ? theme.palette.mode === "dark"
      ? "#0d47a1"
      : "#1976d2"
    : theme.palette.mode === "dark"
    ? "#424242"
    : "#fff",
  color: sent ? "#fff" : theme.palette.mode === "dark" ? "#fff" : "inherit",
  borderRadius: "16px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

const ChatUI = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mode, setMode] = useState("light");
  const [Employees, setEmployees] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const messagesEndRef = useRef(null);
  const MasterpageName = "Chat System";
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };
  const fetchEmployees = async () => {
    WebService({
      endPoint: `User/EmployeeList?where=StatusId eq ${StandardConst.Status.Approved}`,
    }).then((r) => {
      setEmployees(r);
    });
  };
  useEffect(() => {
    fetchEmployees();
  }, []);
  const [bData] = useState([
    {
      title: MasterpageName,
      hrefLink: "#",
    },
    {
      title: " ",
      hrefLink: "#",
    },
  ]);
  const filterEmployees = () => {
    const filteredResults = Employees.filter((employee) =>
      employee.FullName.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    filteredEmployees.length = 0; // Clear the array first
    // setFilteredEmployees((prev) => [...prev, ...filteredResults]);
    setFilteredEmployees((prev) => {
      // Combine existing and new results
      const combinedResults = [...prev, ...filteredResults];
      console.log("Combined results:", combinedResults);
      // Remove duplicates by EmployeeId
      const uniqueResults = Array.from(
        new Map(combinedResults.map((emp) => [emp.EmployeeId, emp])).values()
      );

      return uniqueResults;
    });
  };
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  const BringLastChats = async () => {
    const result = await WebService({
      endPoint: `chat/getLastChats?ToId=${selectedEmployee?.EmployeeId}&FromId=${EmployeeId}`,
    });
    const formattedMessages = result.map((msg) => ({
      ChatId: msg.ChatId,
      Timestamp: new Date(msg.Timestamp).toLocaleString([], {
        dateStyle: "short", // Use 'short' for a concise date format
        timeStyle: "short", // Add this for time formatting
        hour12: true, // Optional: Set to false for 24-hour format
      }),
      sent: msg.FromId == EmployeeId,
      Message: msg.Message,
    }));
    setMessages(formattedMessages);
    ReceiveMessages(messages[0]?.ChatId);
  };

  useEffect(() => {
    if (selectedEmployee) {
      BringLastChats();
    }
  }, [selectedEmployee]);

  const SendMessage = async () => {
    let values = {};
    values.FromId = EmployeeId;
    values.ToId = selectedEmployee.EmployeeId;
    values.Message = newMessage;
    values.CompanyId = newMessage;
    values.Timestamp = new Date().toISOString();
    const responce = await WebService({
      endPoint: `CommonUtility/Chat/`,
      body: values,
    });
    const newMsg = {
      ChatId: responce,
      Message: newMessage,
      sent: true,
      Timestamp: new Date().toLocaleString([], {
        dateStyle: "short",
        timeStyle: "short",
        hour12: true,
      }),
    };
    setMessages([...messages, newMsg]);
    setNewMessage("");
  };

  // function to get persons who started chat with me
  const getChatters = async () => {
    try {
      const data = await WebService({
        endPoint: `chat/getPersonsExstingChatters?EmployeeId=${EmployeeId}`,
      });
      console.log(data);
      setFilteredEmployees(data);
    } catch (error) {
      console.error("Error fetching persons:", error);
    }
  };
  useEffect(() => {
    if (EmployeeId) {
      getChatters();
    }
  }, [EmployeeId]);
  const ReceiveMessages = async (lastmessageid = false) => {
    try {
      console.log("Sending API with lastmessageid:", lastmessageid);

      const data = await WebService({
        endPoint: `chat/getLetestMessage?ToId=${EmployeeId}&FromId=${selectedEmployee.EmployeeId}&LastMessageId=${lastmessageid}`,
      });

      // Process the received data
      if (Array.isArray(data)) {
        const formattedMessages = data.map((msg) => ({
          ChatId: msg.ChatId,
          Timestamp: new Date(msg.Timestamp).toLocaleString([], {
            dateStyle: "short", // Use 'short' for a concise date format
            timeStyle: "short", // Add this for time formatting
            hour12: true, // Optional: Set to false for 24-hour format
          }),
          sent: false,
          Message: msg.Message,
        }));

        // Append new messages to the current messages
        setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);

        // Extract the latest message ID from the response
        const latestId = data[0]?.ChatId;

        console.log("Latest message ID from response:", latestId);

        // Schedule the next API call with the latest ID
        if (latestId) {
          setTimeout(() => {
            ReceiveMessages(latestId);
          }, 10000);
        } else {
          // No new messages, retry with the same ID
          setTimeout(() => {
            ReceiveMessages(lastmessageid);
          }, 10000);
        }
      }
    } catch (error) {
      console.error("Error fetching messages:", error);

      // Retry after an error
      setTimeout(() => {
        ReceiveMessages(lastmessageid);
      }, 10000);
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const contactsList = (
    <Box
      sx={{
        height: "100%",
        maxHeight: "500px",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
   

      <Stack spacing={2}>
        {filteredEmployees.map((item) => (
          <Box
            key={item.EmployeeId}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
              p: 1,
              borderRadius: 1,
              "&:hover": { backgroundColor: theme.palette.action.hover },
            }}
            onClick={() => {
              isMobile && handleDrawerToggle();
              setSelectedEmployee(item);
            }}
          >
            <Avatar
              src={`${StandardConst.images}/${item.ProfileImage}`}
              alt={item.name}
              sx={{ width: 56, height: 56 }}
            />
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {item.FullName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.Designation}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );

  return (
    <>
      <ThemeProvider theme={createTheme({ palette: { mode } })}>
        <CssBaseline />
        <Container maxWidth="xl" sx={{ height: "100vh", p: { xs: 0, md: 2 } }}>
          <div className="d-flex justify-content-between bg-white align-items-center">
            <div className="">
              <h5 className="ms-4 mt-2">{MasterpageName}</h5>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
          </div>
          <AppBar position="static" color="primary" elevation={1}>
  <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    
    {/* Mobile Menu Icon */}
    {isMobile && (
      <IconButton
        color="inherit"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2 }}
      >
        <Menu />
      </IconButton>
    )}
    
    {/* Search Input */}
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, maxWidth: '500px' }}>
      <TextField
        fullWidth
        placeholder="Search contacts"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ marginRight: 1,backgroundColor:"white" }}
      />
      <ActionButton
        onClick={() => {
          filterEmployees();
        }}
        IconName="Search"
        IconColor={theme.palette.primary.main}
        IconTooltip="Search"
        sx={{ display: 'flex', alignItems: 'center' }}
      />
    </Box>

    {/* Theme Toggle Icon */}
    <IconButton onClick={toggleTheme} color="inherit">
      {mode === "dark" ? <LightMode /> : <DarkMode />}
    </IconButton>
  </Toolbar>
</AppBar>

         

          <StyledChatContainer>
            {isMobile ? (
              <Drawer
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
              >
                <Box sx={{}}>{contactsList}</Box>
              </Drawer>
            ) : (
              <SidePanel elevation={2}>{contactsList}</SidePanel>
            )}

            <ChatArea elevation={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar
                    src={`https://${StandardConst.apiBaseUrl}/${selectedEmployee?.ProfileImage}`}
                    alt={selectedEmployee?.FullName}
                  />
                  <Typography variant="h6">
                    {selectedEmployee?.FullName}
                  </Typography>
                </Box>
                <IconButton aria-label="settings">
                  <SettingsSharp />
                </IconButton>
              </Box>

              {selectedEmployee ? (
                <>
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      mb: 2,
                      p: 1,
                    }}
                  >
                    {messages.map((message) => (
                      <MessageContainer key={message.id} sent={message.sent}>
                        <MessageBubble sent={message.sent}>
                          <Typography variant="body1">
                            {message.Message}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ display: "block", mt: 0.5, opacity: 0.7 }}
                          >
                            {message.Timestamp}
                          </Typography>
                        </MessageBubble>
                      </MessageContainer>
                    ))}
                    <div ref={messagesEndRef} />
                  </Box>

                  <Box sx={{ display: "flex", gap: '1' }} className='bg-primary' >
                    <TextField
                      fullWidth
                      placeholder="Type a message"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyPress={(e) => e.key === "Enter" && SendMessage()}
                       sx={{
                        backgroundColor:"#3b82f6",
                        "& .MuiOutlinedInput-root": {
                          border: "none", // Removes the border
                          "& fieldset": {
                            border: "none", // Removes the outline
                          },
                        },
                        "& .MuiInputBase-root": {
                          padding: 0, // Optional: removes padding inside the input box
                          // backgroundColor:"white",

                        },
                      }}
                    />
                    <IconButton
                      
                      onClick={SendMessage}
                      aria-label="send message"
                      className="text-white"
                    >
                      <Send />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <Typography variant="h6" sx={{ alignItems: "center" }}>
                  Select a contact to start chatting
                </Typography>
              )}
            </ChatArea>
          </StyledChatContainer>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default ChatUI;
